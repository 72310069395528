const meta = {
  // Metadata
  siteTitle: 'Natan Maciel Cavalcanti - Creative Fullstack Developer',
  siteDescription:
    'Natan Maciel Cavalcanti - Creative fullstack developer who loves javascript, java, aws and modern web technologies.',
  siteTitleAlt: 'Natan Maciel Cavalcanti',
  siteShortName: 'Natan Maciel Cavalcanti',
  siteUrl: 'https://natanmaciel.github.io', // No trailing slash!
};

const social = {
  siteLogo: `src/static/logo.svg`,
  siteBanner: `${meta.siteUrl}/images/social-banner.png`,
  twitter: '@natan',
};

const website = {
  ...meta,
  ...social,
  disqusShortName: 'natan',
  googleAnalyticsID: 'UA-119972196-1',
  // Manifest
  themeColor: '#6D83F2',
  backgroundColor: '#6D83F2',
};

module.exports = website;
